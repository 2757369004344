import { type MetaFunction } from '@remix-run/cloudflare';
import { DefaultDescription } from '~/tools/meta';

export const meta: MetaFunction = () => {
  return [
    { title: 'UptimeInsight' },
    {
      name: 'description',
      content: DefaultDescription
    }
  ];
};

export default function Component() {
  return <></>;
}
